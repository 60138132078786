import React, { useEffect } from "react"
import { PageProps } from "gatsby"
import styled from "styled-components"
import { CircularProgress } from "@material-ui/core"
import Container from "@material-ui/core/Container"
import CssBaseline from "@material-ui/core/CssBaseline"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import {
  FormContextProvider,
  FormContextType,
  useForm,
} from "../contexts/formContext"
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import ConfirmAddress from "../components/user/ConfirmAddress"
import { useLazyApi } from "../hooks/useLazyApi"
import {
  paymentFormInputs,
  shippingFormInputs,
  userFormInputs,
  billingFormInputs,
} from "../checkout/shared/checkoutFormFields"
import { useLogout } from "../hooks/useLogout"

const LoaderWrapper = styled.div`
  margin-top: 24px;
`

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}))

const ConfirmShippingAddress: React.FC<PageProps> = ({}) => {
  const classes = useStyles()

  const [
    getOrderToConfirmAddress,
    {
      loading: getOrderToConfirmAddressLoading,
      error: getOrderToConfirmAddressError,
      data: getOrderToConfirmAddressData,
      errMsg: getOrderToConfirmAddressMsg,
    },
  ] = useLazyApi("billing", "GetOrderToConfirmAddress")
  const logout = useLogout()
  useEffect(() => {
    logout()

    if (window) {
      const urlParams = new URLSearchParams(window.location.search)
      const tokenEncoded = urlParams.get("token")
      let token = ""

      if (tokenEncoded) {
        token = decodeURIComponent(tokenEncoded)
        try {
          getOrderToConfirmAddress({
            variables: {
              token,
            },
            context: {
              customAuthToken: token,
            },
          })
        } catch (error) {
          console.log(error)
        }
      }
    }
  }, [])

  return (
    <Layout hasHero={false} showFooter={false} showHeader={false}>
      <SEO title="Confirm Shipping Address" />
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h1" variant="h4">
            Confirm Shipping Address
          </Typography>
          {getOrderToConfirmAddressLoading ? (
            <LoaderWrapper>
              <CircularProgress />
            </LoaderWrapper>
          ) : getOrderToConfirmAddressError ? (
            <p>You are not authorized to access this resource.</p>
          ) : (
            <FormContextProvider
              initialInputs={{
                ...userFormInputs,
                ...shippingFormInputs,
                ...billingFormInputs,
                ...paymentFormInputs,
              }}
            >
              <ConfirmAddress
                getOrderToConfirmAddressData={getOrderToConfirmAddressData}
              />
            </FormContextProvider>
          )}
        </div>
      </Container>
    </Layout>
  )
}

export default ConfirmShippingAddress
