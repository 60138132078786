import React, { useState, useEffect } from "react"
import styled from "styled-components"
import Typography from "@material-ui/core/Typography"
import { CircularProgress, Grid, makeStyles, Paper } from "@material-ui/core"

import { Button } from "../ui/Button"
import OrderDetailRow from "./orders/OrderDetailRow"
import SubAndTotalRow from "../cart/SubAndTotalRow"
import { LineItem } from "./orders/orderTypes"
import { UserAddress } from "../../ts/interfaces"
import { FormContextType, useForm } from "../../contexts/formContext"
import { Address } from "../ui/Address"
import { useApiMutation } from "../../hooks/useApiMutation"
import { PaymentIntentResponse } from "../../ts/interfaces"
import {
  billingFields,
  stripeFields,
} from "../../checkout/shared/checkoutFormFields"
import { formatCurrencyString } from "../../utils/format"
import { Currency } from "../../checkout/types/checkoutTypes"
import CheckoutPaymentWrapper from "../../checkout/components/steps/CheckoutPaymentWrapper"
import CheckoutShipping from "../../checkout/components/steps/CheckoutShipping"
import { VAT_REQUIRED_COUNTRIES } from "../../checkout/components/steps/CheckoutShippingAddress"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  width: 100%;
`

const AddressWrapper = styled.div`
  margin-top: 12px;
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
`

const NameText = styled.p`
  font-weight: 600;
`

const ButtonsWrapper = styled.div`
  textalign: center;
  margin: 2rem;
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
`

const PayAndConfirmWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
    borderRadius: 15,
  },
}))

const ConfirmAddress = ({
  getOrderToConfirmAddressData,
}: {
  getOrderToConfirmAddressData: {
    GetOrderToConfirmAddress: {
      id: string
      lineItems: LineItem[]
      shippingAddress: UserAddress[]
      email: string
    }
  }
}) => {
  const classes = useStyles()

  const [isPaying, setIsPaying] = useState(false)
  const [havBilling, setHaveBilling] = useState(false)

  const [errorMsg, setErrorMsg] = useState("")
  const [fixedPaymentAmt, setFixedPaymentAmt] = useState("")
  const { formState, formActions } = useForm() as FormContextType

  const shippingAddress: UserAddress =
    getOrderToConfirmAddressData &&
    getOrderToConfirmAddressData?.GetOrderToConfirmAddress?.shippingAddress[0]

  const [isEditAddressMode, setisEditAddressMode] = useState<boolean>(false)
  const [forceEditAddressMode, setForceEditAddressMode] = useState<boolean>(
    false
  )
  const [shippingOrder, setShippingOrder] = useState<PaymentIntentResponse>()

  const [selectedAddress, setSelectedAddress] = useState<Partial<UserAddress>>({
    id: shippingAddress?.id || "",
    firstName: shippingAddress?.firstName || "",
    lastName: shippingAddress?.lastName || "",
    city: shippingAddress?.city || "",
    country:
      shippingAddress?.country
        ?.split(" ")
        .map((c: string) => c.charAt(0).toUpperCase() + c.slice(1))
        .join(" ") || "",
    postal: shippingAddress?.postal || "",
    province: shippingAddress?.province || "",
    street: shippingAddress?.street || "",
    unit: shippingAddress?.unit || "",
    phone: shippingAddress?.phone || "",
    vat: shippingAddress?.vat || "",
  })

  useEffect(() => {
    if (selectedAddress) {
      let data: any = {
        firstName: selectedAddress.firstName,
        lastName: selectedAddress.lastName,
        email: getOrderToConfirmAddressData?.GetOrderToConfirmAddress?.email,
      }
      ;[
        "FirstName",
        "LastName",
        "Street",
        "Unit",
        "City",
        "Country",
        "Postal",
        "Phone",
        "Province",
        "Vat",
      ].forEach(field => {
        const val = (selectedAddress as any)[
          field[0].toLowerCase() + field.substring(1)
        ]
        if (val) {
          data["shipping" + field] = val
        }
      })
      formActions.setFormData(data)
      if (
        !selectedAddress.country ||
        (VAT_REQUIRED_COUNTRIES.includes(selectedAddress.country) &&
          !selectedAddress.vat)
      ) {
        setForceEditAddressMode(true)
      }
    }
  }, [])

  const [success, setSuccess] = useState<boolean>(false)

  useEffect(() => {
    formActions.setSkipValidationFunction((field: string): boolean => {
      return [...stripeFields, ...billingFields].includes(field)
    })
  }, [])

  const [
    updateAndConfirmShippingAddress,
    {
      loading: updateAndConfirmShippingAddressLoading,
      error: updateAndConfirmShippingAddressError,
      data: updateAndConfirmShippingAddressData,
      errMsg: updateAndConfirmShippingAddressErrMsg,
    },
  ] = useApiMutation("billing", "UpdateAndConfirmShippingAddress")

  useEffect(() => {
    if (updateAndConfirmShippingAddressError) {
      setErrorMsg(
        updateAndConfirmShippingAddressError.message.startsWith(
          "Your token is no longer valid"
        )
          ? updateAndConfirmShippingAddressError.message
          : "Sorry there was an error. Please try again or contact our customer support team."
      )
    }
  }, [updateAndConfirmShippingAddressError])

  const handleConfirm = () => {
    const urlParams = new URLSearchParams(window?.location?.search)
    const tokenEncoded = urlParams.get("token")
    let token = ""

    //TODO send address confirmation / pay
    let addressData = {
      firstName: selectedAddress.firstName,
      lastName: selectedAddress.lastName,
      unit: selectedAddress.unit,
      city: selectedAddress.city,
      country: selectedAddress.country,
      postal: selectedAddress.postal,
      province: selectedAddress.province,
      street: selectedAddress.street,
      phone: selectedAddress.phone,
      vat: selectedAddress.vat,
    }

    if (isEditAddressMode || forceEditAddressMode) {
      if (!formActions.isValid()) {
        return
      }
      addressData = {
        firstName: formState.inputs.shippingFirstName.value,
        lastName: formState.inputs.shippingLastName.value,
        unit: formState.inputs.shippingUnit.value,
        city: formState.inputs.shippingCity.value,
        country: formState.inputs.shippingCountry.value,
        postal: formState.inputs.shippingPostal.value,
        province: formState.inputs.shippingProvince.value,
        street: formState.inputs.shippingStreet.value,
        phone: formState.inputs.shippingPhone.value,
        vat: formState.inputs.shippingVat.value,
      }
    }

    const { id } = getOrderToConfirmAddressData.GetOrderToConfirmAddress

    if (tokenEncoded) {
      token = decodeURIComponent(tokenEncoded)
      try {
        return updateAndConfirmShippingAddress({
          variables: {
            input: {
              orderId: id,
              shippingAddressId: selectedAddress.id,
              ...addressData,
            },
          },
          context: {
            customAuthToken: token,
          },
        })
      } catch (error) {
        console.log(error)
      }
    }
  }

  useEffect(() => {
    if (
      updateAndConfirmShippingAddressData &&
      updateAndConfirmShippingAddressData?.UpdateAndConfirmShippingAddress
        ?.shippingOrder
    ) {
      // a payment is due for shipping
      const shippingOrderData: PaymentIntentResponse =
        updateAndConfirmShippingAddressData?.UpdateAndConfirmShippingAddress
          ?.shippingOrder

      //copy shipping address to billing
      const fields = [
        "FirstName",
        "LastName",
        "Country",
        "Street",
        "Unit",
        "City",
        "Province",
        "Postal",
        "Phone",
        "Vat",
      ]
      const billingData: Record<string, string | number> = {
        cardHolderName:
          formState.inputs[`shippingFirstName`].value +
          " " +
          formState.inputs[`shippingLastName`].value,
      }
      fields.forEach(field => {
        billingData[`billing${field}`] =
          formState.inputs[`shipping${field}`].value
      })
      formActions.setFormData(billingData)

      setShippingOrder(shippingOrderData)

      let fixedPaymentAmount = shippingOrderData
        ? formatCurrencyString({
            value: shippingOrderData.totalAmount!,
            currency: shippingOrderData.currency!.toUpperCase(),
          })
        : ""

      if (
        shippingOrderData &&
        shippingOrderData.currency?.toUpperCase() === "USD"
      ) {
        fixedPaymentAmount += " USD"
      }
      setFixedPaymentAmt(fixedPaymentAmount)
    } else if (
      updateAndConfirmShippingAddressData &&
      updateAndConfirmShippingAddressData?.UpdateAndConfirmShippingAddress
        ?.orderId
    ) {
      setSuccess(true)
    }
  }, [updateAndConfirmShippingAddressData])

  return (
    <Wrapper>
      {updateAndConfirmShippingAddressLoading ? (
        <Paper className={classes.root} elevation={10}>
          <CircularProgress />
        </Paper>
      ) : success ? (
        <Typography component="h3" variant="h6">
          Success! Thank you for confirming your address.
        </Typography>
      ) : shippingOrder ? (
        <Paper className={classes.root} elevation={10}>
          <Typography component="h3" variant="h6">
            Shipping fees are required in order to confirm your order{" "}
            {fixedPaymentAmt}.
          </Typography>
          <PayAndConfirmWrapper>
            <Grid item xs={12}>
              {!havBilling ? (
                <CheckoutShipping
                  type="billing"
                  isFixedPayment={true}
                  hideCardholderName={true}
                  setHaveBilling={setHaveBilling}
                />
              ) : (
                <CheckoutPaymentWrapper
                  isPaying={isPaying}
                  setIsPaying={setIsPaying}
                  fixedPaymentIntent={shippingOrder}
                  couponCode={""} // TODO: Update to add to global state
                />
              )}
            </Grid>
          </PayAndConfirmWrapper>
        </Paper>
      ) : (
        <>
          <Typography component="h3" variant="h6">
            Order Details
          </Typography>
          {getOrderToConfirmAddressData &&
            getOrderToConfirmAddressData.GetOrderToConfirmAddress.lineItems.map(
              (item: LineItem) => {
                if (item.quantity > 0) {
                  return (
                    <OrderDetailRow
                      key={item.id}
                      desc={`${item.description} x${item.quantity}`}
                      quantity={item.quantity}
                      unitAmount={item.unitAmount}
                      currency={item.currency as Currency}
                    />
                  )
                }
                return (
                  <SubAndTotalRow
                    key={item.id}
                    value={item.unitAmount}
                    currency={item.currency as Currency}
                    desc={item.description}
                    isTotal={item.description === "Total"}
                  />
                )
              }
            )}

          {shippingAddress && (
            <Paper className={classes.root} elevation={10}>
              <AddressWrapper>
                <form>
                  {isEditAddressMode || forceEditAddressMode ? (
                    <Grid container spacing={2}>
                      <Address
                        type="shipping"
                        address={{
                          firstName: selectedAddress.firstName,
                          lastName: selectedAddress.lastName,
                          unit: selectedAddress.unit,
                          city: selectedAddress.city,
                          country: selectedAddress.country,
                          postal: selectedAddress.postal,
                          province: selectedAddress.province,
                          street: selectedAddress.street,
                          phone: selectedAddress.phone,
                          vat: selectedAddress.vat,
                        }}
                      />
                    </Grid>
                  ) : (
                    <>
                      <Typography component="h3" variant="h6">
                        Shipping Address
                      </Typography>

                      <NameText>
                        {shippingAddress.firstName} {shippingAddress.lastName}
                      </NameText>

                      <p>
                        {shippingAddress.street}
                        {shippingAddress.unit
                          ? `, unit ${shippingAddress.unit}`
                          : ""}
                      </p>
                      <p>
                        {shippingAddress.city},{" "}
                        {shippingAddress.province
                          ? `${shippingAddress.province}, `
                          : ""}{" "}
                        {shippingAddress.country}
                      </p>
                      <p>{shippingAddress.postal}</p>
                      <p>Phone: {shippingAddress.phone}</p>
                    </>
                  )}
                  <ButtonsWrapper>
                    {!forceEditAddressMode && (
                      <Button
                        sectionId="add_shipping_dialog"
                        id={"change_address"}
                        type="secondaryOutline"
                        // disabled={createLoading}
                        title={isEditAddressMode ? "Cancel" : "Edit"}
                        onClick={() => {
                          setisEditAddressMode(!isEditAddressMode)
                        }}
                      />
                    )}

                    <Button
                      sectionId="add_shipping_dialog"
                      id="confirm"
                      type="primary"
                      title="Confirm"
                      onClick={handleConfirm}
                    />
                  </ButtonsWrapper>
                  {errorMsg && <p className="error">{errorMsg}</p>}
                </form>
              </AddressWrapper>
            </Paper>
          )}
        </>
      )}
    </Wrapper>
  )
}

export default ConfirmAddress
