import React from "react"
import styled from "styled-components"
import { Hidden, Grid } from "@material-ui/core"
import { Dividor } from "../../ui/line"
import { formatCurrencyString } from "../../../utils/format"
import { Currency } from "../../../checkout/types/checkoutTypes"

const ItemText = styled.span`
  font-size: 0.9rem;
  margin: 0.2rem 0;
  line-height: 1rem;
  display: inline-block;
`

const CartItem = ({
  desc,
  quantity,
  unitAmount,
  currency,
  unitSmallText,
  unitQuantStr,
}: {
  desc: string
  unitAmount: number | string
  quantity: number | string
  currency: Currency
  unitSmallText?: string
  unitQuantStr?: string
}) => {
  return (
    <>
      <Grid container spacing={2} alignContent="center" alignItems="center">
        <Grid item xs={12} sm={6}>
          <ItemText>{desc}</ItemText>
        </Grid>

        <Grid item xs={6} sm={3} style={{ textAlign: "right" }}>
          <ItemText>
            <Hidden smUp>{unitSmallText ?? "Unit Amount: "}</Hidden>
            {typeof unitAmount === "string"
              ? unitAmount
              : formatCurrencyString({
                  value: unitAmount,
                  currency: currency.toUpperCase(),
                })}
          </ItemText>
        </Grid>
        <Grid item xs={6} sm={3} style={{ textAlign: "right" }}>
          <ItemText>
            {typeof unitAmount === "string" || typeof quantity === "string"
              ? unitQuantStr
              : formatCurrencyString({
                  value: unitAmount * quantity,
                  currency: currency.toUpperCase(),
                })}
          </ItemText>
        </Grid>
      </Grid>
      <Dividor color="var(--color-grey-2)" />
    </>
  )
}

export default CartItem
