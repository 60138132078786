import React, { ReactNode } from "react"
import styled from "styled-components"
import { useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import { formatCurrencyString } from "../../utils/format"
import { Currency } from "../../checkout/types/checkoutTypes"

const SubTotalRow = styled.div<{ isMobile: boolean }>`
  margin: ${props => (props.isMobile ? ".5rem 0" : "1.5rem 0")};
  text-align: right;
  color: var(--color-grey-3);
  font-size: 1.2rem;
  display: flex;
  justify-content: flex-end;
  #cart-total {
    flex: 0 0 12rem;
  }
  .desc {
    margin-right: 2rem;
  }
  &.total span {
    color: var(--color-primary-dark);
    font-size: 1.3rem !important;
  }
`

const SubAndTotalRow = ({
  value,
  currency,
  desc,
  isTotal,
  customNaNMessage,
  children,
}: {
  value: number
  currency: Currency
  desc: string
  isTotal: boolean
  customNaNMessage?: string
  children?: ReactNode
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <SubTotalRow className={isTotal ? "total" : "subtotal"} isMobile={isMobile}>
      {children}
      <span className="desc">{desc}</span>
      <span id={`${isTotal ? "cart-total" : "row-total"}`}>
        {isNaN(value)
          ? customNaNMessage
            ? customNaNMessage
            : "-"
          : formatCurrencyString({
              value,
              currency: currency.toUpperCase(),
            })}
        {isTotal ? ` ${currency.toUpperCase()}` : ""}
      </span>
    </SubTotalRow>
  )
}

export default SubAndTotalRow
